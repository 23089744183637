import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import Cookies from "js-cookie";
import NavbarButtons from "./navbarButtons";
import { itemsValue101, itemsValue2, itemsValue4, itemsValue7 } from "./itemsValues";

const Navbar = (props) => {
  const [hideNav, setHideNav] = useState(true);
  const [promoValue, setPromoValue] = useState({});
  const [navDisplay, setNavDisplay] = useState(false);
  useEffect(() => {
    let totalPrice = Cookies.get("totalPrice");
    let cart = Cookies.get("cart");
    if (cart == "undefined" || cart == undefined || cart == null) {
    } else {
      if (cart && totalPrice != 0 && totalPrice != undefined) {
        setPrice(parseInt(totalPrice));
      }
    }
  }, []);
  const [friday, setFriday] = useState(null);
  const [price, setPrice] = useState(0);
  const [num, setNum] = useState(undefined);
  const router = useRouter();
  const [hideBanner, setHideBanner] = useState(false);
  const [group, setGroup] = useState(router.query);
  const [isScrollClass, setIsScrollClass] = useState(false);
  const [menuVal, setMenuVal] = useState(7);
  const [menuMobileVal, setMenuMobileVal] = useState(0);
  const [show_price, setShow_Price] = useState(0)
  const [isToggle, setIsToggle] = useState(false);
  const [isToggleMegaInner, setIsToggleInner] = useState(false);

  const textFromHeader = {
    description: `Fall is here 🍂: First Month as low as $${show_price}, get started now!`,
    url: "/#view-tests",
    textUrl: "Start Now"
  }

  const promo = [
    { name: "THANKS15", price: 15 },
    { name: "20THANKS", price: 20 },
    { name: "23IN23", price: 23 },
  ];
  useEffect(() => {
    if (router?.query?.promo) {
      const findpromo = promo.find(
        (x) => x.name.toLowerCase() == router.query.promo.toLowerCase()
      );
      if (findpromo) {
        setPromoValue((promoValue) => {
          return {
            ...promoValue,
            promo_amount: findpromo.price,
            promo_name: findpromo.name,
          };
        });
      }
    }
    let cart1 = Cookies.get("cart");
    if (cart1 == "undefined" || cart1 == undefined || cart1 == null) {
    } else {
      let cart = JSON.parse(cart1);
      setNum(cart.length);
    }
  }, [num]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (router.query && router?.query?.shopping) {
      if (router.query.shopping.toLowerCase() == "true") {
        setHideNav(true);
        setMenuVal(2)
      }
    }
    if (router.query && router?.query?.variant && router?.query?.variant.toLowerCase() === "generic") {
      setShow_Price(479)
    }
  }, []);

  const toggleShow = () => {
    isToggle == false
      ? (document.body.style.overflowY = "hidden")
      : document.body.removeAttribute("style");
    isToggle ? setIsToggle(false) : setIsToggle(true);
    if (isToggle) {
      FreshworksWidget("show");
    } else {
      FreshworksWidget("hide");
    }
  };

  const toggleShowMegaInner = (val) => {
    setMenuMobileVal(val);
    isToggleMegaInner ? setIsToggleInner(false) : setIsToggleInner(true);
  };

  const activeMenu = (val) => {
    setMenuVal(val);
  };

  const goThrough = () => {
    setIsToggle(false);
    setIsToggleInner(false);
    isToggle == false
      ? (document.body.style.overflowY = "hidden")
      : document.body.removeAttribute("style");
  };

  const handleScroll = () => {
    if (window.scrollY > 150) {
      setIsScrollClass(true);
    } else {
      setIsScrollClass(false);
    }
  };

  return (
    <>
      <div
        onClick={(e) => {
          setNavDisplay(false);
        }}
        className={navDisplay == true ? "backdrop1 loader-active1" : ""}
      ></div>

      {router.pathname == "/checkout" ? (
        ""
      ) : (
        <div
          className={
            isScrollClass == true
              ? "sticky-navbar menu-wrapper"
              : "sticky-navbar menu-wrapper"
          }
        >

          <div
            className={
              hideBanner == true
                ? "hideBanner1"
                : "alert alert-nav  text-center"
            }
          >
            {/* <span style={{ fontWeight: "500" }}>
              Now Offering in-lab and at-home Nurse Visits Nationwide
            </span> */}
            {friday ? <span style={{ fontWeight: "500" }}>
              {textFromHeader.description}
              <Link href={textFromHeader.url} ><span className="start-now-nav"> {textFromHeader.textUrl} &#62;</span></Link>
            </span> :
              <>
                {router.asPath == "/product/weight_loss_mw" || router.asPath == "/product/weight_loss" ?
                  <span style={{ fontWeight: "500" }}>
                    {textFromHeader.description}
                    <Link href={textFromHeader.url} ><span className="start-now-nav"> {textFromHeader.textUrl} &#62;</span></Link>
                  </span> :
                  <>
                    {router.asPath.includes("weight_loss") ||
                      router.asPath.includes("how-it-works") ||
                      router.asPath.includes("medication") ||
                      router.asPath.includes("faq") ||
                      router.asPath.includes("eligibility") ||
                      router.asPath.includes("pricing") ? (
                      <>
                        {promoValue?.promo_name && promoValue?.promo_amount ? (
                          <span style={{ fontWeight: "500" }}>
                            Congratulations: Promo code {promoValue.promo_name} applied
                            for a ${promoValue.promo_amount} discount! Add to cart to
                            enjoy savings. <a className="start-now-nav" href="#view-tests">Start Now &#62;</a>
                          </span>
                        ) : (
                          <>
                            {router?.query?.shopping == "true" ? (
                              <span style={{ fontWeight: "500" }}>
                                {textFromHeader.description}
                                <Link href={textFromHeader.url} ><span className="start-now-nav"> {textFromHeader.textUrl} &#62;</span></Link>
                              </span>
                            ) : (
                              <>
                                {
                                  ((router?.query?.variant == "dec") || ((router?.query?.type) || (router?.query?.type2) || (router?.query?.dki)) && (router?.query?.variant == "dec")) ? (

                                    <span style={{ fontWeight: "500" }}>
                                      {textFromHeader.description}
                                      <Link href={textFromHeader.url} ><span className="start-now-nav"> {textFromHeader.textUrl} &#62;</span></Link>
                                    </span>
                                  ) : (
                                    <span style={{ fontWeight: "500" }}>
                                      {textFromHeader.description}
                                      <Link href={textFromHeader.url}><span className="start-now-nav"> {textFromHeader.textUrl} &#62;</span></Link>
                                    </span>
                                  )
                                }
                              </>
                            )}

                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {
                          promoValue?.promo_name && promoValue?.promo_amount ?
                            (
                              <span style={{ fontWeight: "500" }}>
                                Congratulations: Promo code {promoValue.promo_name} applied
                                for a ${promoValue.promo_amount} discount! Add to cart to
                                enjoy savings. <a className="start-now-nav" href="#view-tests">Start Now &#62;</a>
                              </span>
                            ) : (
                              <>
                                {router.pathname === "/testosterone" ?
                                  <span style={{ fontWeight: "500" }}>
                                    America's Only National Testosterone Therapy Brand <a className="start-now-nav" href="#view-tests">Start Now &#62;</a>
                                  </span> :
                                  <span style={{ fontWeight: "500" }}>
                                    {textFromHeader.description}
                                    <Link href={textFromHeader.url} ><span className="start-now-nav"> {textFromHeader.textUrl} &#62;</span></Link>
                                  </span>

                                }
                              </>
                            )
                        }
                      </>
                    )}
                  </>}
              </>
            }
          </div>
          <nav className="custom-navbar ">
            <NavbarButtons router={router}
              toggleShow={toggleShow}
              setNavDisplay={setNavDisplay}
              group={group}
              hideNav={hideNav}
              navDisplay={navDisplay}
              activeMenu={activeMenu}
              menuVal={menuVal}
            />
            <div
              onClick={(e) => {
                setIsToggle(false);
                setIsToggleInner(false);
              }}
              className={
                isToggle == true ? "loader-active backdrop" : "backdrop"
              }
            ></div>
            {/* Mobile Sidebar Main LInks */}
            <>
              {hideNav == true ?
                <div
                  className={`collapse navbar-collapse ${isToggle ? "show" : ""}`}
                  id="mobileMegaMenuMain"
                >
                  <div className="mega__menu__mobile__sidebar">
                    <a
                      href="#"
                      className="mega__mobile__close-btn w3-large"
                      onClick={(e) => toggleShow()}
                    >
                      &times;
                    </a>

                    <span className="our-health"> All Treatments </span>


                    <span
                      className="mobile__links"
                      onClick={(e) => toggleShowMegaInner(7)}
                    >
                      <img
                        src="/menu/icon_weightloss.webp"
                        className="Mobile-img0menu"
                        loading="lazy"
                      />
                      Weight Loss
                    </span>

                    <hr />
                    <button
                      className="btn btn-lg-primary w-100"
                      onClick={(e) => {
                        router.push("/login");
                      }}
                    >
                      Login
                    </button>
                    <button
                      className="btn btn-lg-primary main-white-btn mt-3 w-100"
                      onClick={(e) => {
                        window.location.href = "/#view-tests";
                        setIsToggle(false);
                      }}
                    >
                      View Popular Programs
                    </button>
                  </div>
                </div> :
                <div
                  className={`collapse navbar-collapse ${isToggle ? "show" : "hide"}`}
                  id="mobileMegaMenuMain"
                >
                  <div className="mega__menu__mobile__sidebar">
                    <a
                      href="#"
                      className="mega__mobile__close-btn w3-large"
                      onClick={(e) => toggleShow()}
                    >
                      &times;
                    </a>
                    <span className="our-health">Our Health Tests</span>
                    <span
                      className="mobile__links"
                      onClick={(e) => toggleShowMegaInner(1)}
                    >
                      <img
                        src="/menu/icon_allergies.webp"
                        className="Mobile-img0menu"
                        loading="lazy"
                      />{" "}
                      Allergies
                    </span>
                    <span
                      className="mobile__links"
                      onClick={(e) => toggleShowMegaInner(2)}
                    >
                      <img
                        src="/menu/icon_womenshealth.webp"
                        className="Mobile-img0menu"
                        loading="lazy"
                      />{" "}
                      Women’s Health
                    </span>

                    <hr />
                    <span className="our-health">Our Health Treatments</span>
                    <span
                      className="mobile__links"
                      onClick={(e) => toggleShowMegaInner(7)}
                    >
                      <img
                        src="/menu/icon_weightloss.webp"
                        className="Mobile-img0menu"
                        loading="lazy"
                      />{" "}
                      Weight Loss Programs
                    </span>
                    <hr />
                    <span className="our-health">Our Health Products</span>
                    <span
                      className="mobile__links"
                      onClick={(e) => {
                        goThrough()
                        router.push("weight_loss")
                      }}
                    >
                      <img
                        src="/menu/icon_weightloss.webp"
                        className="Mobile-img0menu"
                        loading="lazy"
                      />{" "}
                      GLP Weight Loss
                    </span>

                    <hr />
                    <button
                      className="btn btn-lg-primary w-100"
                      onClick={(e) => {
                        router.push("/login");
                      }}
                    >
                      Login
                    </button>
                    <button
                      className="btn btn-lg-primary main-white-btn mt-3 w-100"
                      onClick={(e) => {
                        window.location.href = "/#view-tests";
                        setIsToggle(false);
                      }}
                    >
                      View Popular Programs
                    </button>
                  </div>
                </div>}
            </>
            {/* Mobile Sidebar Inner LInks */}
            <div
              className={`collapse navbar-collapse ${isToggleMegaInner ? "show" : ""
                }`}
              id="mobileMegaMenuInner"
            >
              <div className="mega__menu__mobile__sidebar Inner__sidebar">
                <a
                  href="#"
                  className="mega__mobile__close-btn w3-large"
                  onClick={(e) => toggleShowMegaInner()}
                >
                  &times;
                </a>
                <div className="row">
                  <div className="col-md-3 ">
                    <div
                      className={
                        menuMobileVal === 1
                          ? "mega-inner-items mega-mobile-inner-items border-0 show-mega-menu"
                          : "hide-mega-menu"
                      }
                    >
                      <div className="inner-mobile-menu-items">
                        <Link href="/allergies#view-tests">
                          <span
                            className="d-block"
                            onClick={(e) => {
                              goThrough();
                            }}
                          >
                            36 Food Allergy Panel
                          </span>
                        </Link>
                        <Link href="/allergies#view-tests">
                          <span
                            className="d-block"
                            onClick={(e) => {
                              goThrough();
                            }}
                          >
                            Indoor & Outdoor Allergy
                          </span>
                        </Link>
                        <Link href="/allergies#view-tests">
                          <span
                            className="d-block"
                            onClick={(e) => {
                              goThrough();
                            }}
                          >
                            Celiac Complete
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div
                      className={
                        menuMobileVal === 2
                          ? "mega-inner-items mega-mobile-inner-items border-0 show-mega-menu"
                          : "hide-mega-menu"
                      }
                    >
                      <div className="inner-mobile-menu-items">
                        {itemsValue2.map((item, index) => (
                          <Link key={index} href={item.href}>
                            <span
                              className="d-block"
                              onClick={(e) => {
                                goThrough();
                              }}
                            >
                              {item.text}
                            </span>
                          </Link>
                        ))}


                      </div>
                    </div>
                    <div
                      className={
                        menuMobileVal === 3
                          ? "mega-inner-items mega-mobile-inner-items border-0 show-mega-menu"
                          : "hide-mega-menu"
                      }
                    >
                      <div className="inner-mobile-menu-items">
                        <Link href="/menhealth#view-tests">
                          <span
                            className="d-block"
                            onClick={(e) => {
                              goThrough();
                            }}
                          >
                            Male Hormone Complete
                          </span>
                        </Link>
                        <Link href="/menhealth#view-tests">
                          <span
                            className="d-block"
                            onClick={(e) => {
                              goThrough();
                            }}
                          >
                            Male Hormone Standard
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div
                      className={
                        menuMobileVal === 4
                          ? "mega-inner-items mega-mobile-inner-items border-0 show-mega-menu"
                          : "hide-mega-menu"
                      }
                    >
                      <div className="inner-mobile-menu-items">
                        {itemsValue4.map((item, index) => (
                          <Link key={index} href={item.href}>
                            <span
                              className="d-block"
                              onClick={(e) => {
                                goThrough();
                              }}
                            >
                              {item.text}
                            </span>
                          </Link>
                        ))}
                      </div>
                    </div>

                    <div
                      className={
                        menuMobileVal === 6
                          ? "mega-inner-items mega-mobile-inner-items border-0 show-mega-menu"
                          : "hide-mega-menu"
                      }
                    >
                      <div className="inner-mobile-menu-items">
                        <h4>Energy </h4>
                        <Link href="/thyroid#view-tests">
                          <span
                            className="d-block"
                            onClick={(e) => {
                              goThrough();
                            }}
                          >
                            Thyroid Standard
                          </span>
                        </Link>
                        <Link href="/thyroid#view-tests">
                          <span
                            className="d-block"
                            onClick={(e) => {
                              goThrough();
                            }}
                          >
                            Thyroid Complete
                          </span>
                        </Link>
                        <h4 className="pt-2 ">Heart Health</h4>
                        <Link
                          href="/heart_health#view-tests"
                          onClick={(e) => {
                            goThrough();
                          }}
                        >
                          <span className="d-block">Hemoglobin A1C Test</span>
                        </Link>
                        <Link
                          href="/heart_health#view-tests"
                          onClick={(e) => {
                            goThrough();
                          }}
                        >
                          <span className="d-block">
                            Cholesterol & Lipids Panel
                          </span>
                        </Link>
                        <h4 className="pt-2 ">Sleep Stress</h4>
                        <Link
                          href="/sleep_stress#view-tests"
                          onClick={(e) => {
                            goThrough();
                          }}
                        >
                          <span className="d-block">
                            Sleep & Stress Hormone Test
                          </span>
                        </Link>
                        <Link
                          href="/sleep_stress#view-tests"
                          onClick={(e) => {
                            goThrough();
                          }}
                        >
                          <span className="d-block">Cortisol Test</span>
                        </Link>
                        <h4 className="pt-2 ">Male Hormone Testing</h4>
                        <Link href="/menhealth#view-tests">
                          <span
                            className="d-block"
                            onClick={(e) => {
                              goThrough();
                            }}
                          >
                            Male Hormone Complete
                          </span>
                        </Link>
                        <Link href="/menhealth#view-tests">
                          <span
                            className="d-block"
                            onClick={(e) => {
                              goThrough();
                            }}
                          >
                            Male Hormone Standard
                          </span>
                        </Link>
                        <h4 className="pt-2 ">Female Hormone Testing</h4>
                        <Link href="/womenhealth#view-tests">
                          <span
                            className="d-block"
                            onClick={(e) => {
                              goThrough();
                            }}
                          >
                            Female Hormone Complete
                          </span>
                        </Link>
                        <Link href="/womenhealth#view-tests">
                          <span
                            className="d-block"
                            onClick={(e) => {
                              goThrough();
                            }}
                          >
                            Female Hormone Standard
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div
                      className={
                        menuMobileVal === 7
                          ? "mega-inner-items mega-mobile-inner-items border-0 show-mega-menu"
                          : "hide-mega-menu"
                      }
                    >
                      {hideNav == false ?
                        <div className="inner-mobile-menu-items">
                          <Link href="/weight_loss">
                            <span
                              className="d-block"
                              onClick={(e) => {
                                goThrough();
                              }}
                            >
                              Home
                            </span>
                          </Link>
                          <Link href="/weight_loss/medication">
                            <span
                              className="d-block"
                              onClick={(e) => {
                                goThrough();
                              }}
                            >
                              Medication
                            </span>
                          </Link>
                          <Link href="/weight_loss">
                            <span
                              className="d-block"
                              onClick={(e) => {
                                goThrough();
                              }}
                            >
                              Pricing
                            </span>
                          </Link>
                        </div> :
                        <div className="inner-mobile-menu-items">
                          {itemsValue7.map((item, index) => (
                            <Link key={index} href={item.href}>
                              <span
                                className="d-block"
                                onClick={(e) => {
                                  goThrough();
                                }}
                              >
                                {item.text}
                              </span>
                            </Link>
                          ))}
                        </div>
                      }
                    </div>
                    <div
                      className={
                        menuMobileVal === 8
                          ? "mega-inner-items mega-mobile-inner-items border-0 show-mega-menu"
                          : "hide-mega-menu"
                      }
                    >
                      <div className="inner-mobile-menu-items">
                        <Link href="/acne_care">
                          <span
                            className="d-block"
                            onClick={(e) => {
                              goThrough();
                            }}
                          >
                            Accutane Care Program
                          </span>
                        </Link>
                        <Link href="/acne_care">
                          <span
                            className="d-block"
                            onClick={(e) => {
                              goThrough();
                            }}
                          >
                            Accutane Care Complete Program
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div
                      className={
                        menuMobileVal === 100
                          ? "mega-inner-items mega-mobile-inner-items border-0 show-mega-menu"
                          : "hide-mega-menu"
                      }
                    >
                      <div className="inner-mobile-menu-items">
                        <Link href="/testosterone">
                          <span
                            className="d-block"
                            onClick={(e) => {
                              goThrough();
                            }}
                          >
                            Testosterone Complete Program
                          </span>
                        </Link>
                        <Link href="/testosterone">
                          <span
                            className="d-block"
                            onClick={(e) => {
                              goThrough();
                            }}
                          >
                            Testosterone Standard Program
                          </span>
                        </Link>

                      </div>
                    </div>
                    <div
                      className={
                        menuMobileVal === 101
                          ? "mega-inner-items mega-mobile-inner-items border-0 show-mega-menu"
                          : "hide-mega-menu"
                      }
                    >
                      <div className="inner-mobile-menu-items">
                        {itemsValue101.map((item, index) => (
                          <Link key={index} href={item.href}>
                            <span
                              className="d-block"
                              onClick={(e) => {
                                goThrough();
                              }}
                            >
                              {item.text}
                            </span>
                          </Link>
                        ))}
                      </div>
                    </div>
                    <button
                      className="btn btn-lg-primary w-100"
                      onClick={(e) => {
                        router.push("/login");
                      }}
                    >
                      Login
                    </button>
                    <button
                      className="btn btn-lg-primary main-white-btn mt-3 w-100"
                      onClick={(e) => {
                        window.location.href = "/#view-tests";
                        setIsToggle(false);
                      }}
                    >
                      View Popular Programs
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </nav>

        </div>
      )}
    </>
  );
};
export default Navbar;

export async function getServerSideProps(context) {
  const data = context.query;
  return {
    props: {
      shopping: data.shopping ? data.shopping : null,
      quiz: data.quiz ? data.quiz : null,
      call: data.call ? data.call : null,
    }, // will be passed to the page component as props
  };
}
