import Link from "next/link";
import { isMobile } from "react-device-detect";
import MenuItems from "./menuItems";

const NavbarButtons = ({ router, toggleShow, setNavDisplay, group, hideNav, navDisplay, activeMenu, menuVal }) => {



  return (
    <div className="container">
      <div className="row w-100 gx-0 menu-items">
        <div style={{ paddingTop: "5px", paddingBottom: "5px" }} className="col-md-2 col-6 position-relative">
          {router.pathname == "/checkout" ? (
            <a className="navbar-brand">
              <img alt="NextMed Logo" src="/images/logo.webp" />
            </a>
          ) : (
            <>
              {hideNav == true || router.asPath == "/product/weight_loss_mw" || router.asPath == "/product/weight_loss" ? (
                <a href="/">
                  <>
                    <span className="navbar-brand">
                      <img alt="NextMed Logo" src="/images/logo.webp" />
                    </span>
                    {/* {isMobile == true &&
                      (
                        <>
                          <br />
                          <span className="america-tag-mobile">America's Weight Loss Experts&#8482;</span>
                        </>
                      )
                    } */}
                  </>
                </a>
              ) : (
                <a href="/">
                  <>
                    <a className="navbar-brand">

                      <img alt="NextMed Logo" src="/images/logo.webp" />
                    </a>
                    {
                      isMobile == true && (
                        <>
                          <span className="america-tag-mobile">America's Weight Loss Experts&#8482;</span>
                        </>
                      )
                    }
                  </>
                </a>
              )}
            </>
          )}
        </div>
        {isMobile == false && (
          <div className="col-md-3 container-america-tag">
            <span className="america-tag">America's Weight Loss Experts&#8482;
            </span></div>)
        }
        {(group && router.query.group) ||
          router.query.quest != undefined ? (
          ""
        ) : (
          <>
            {isMobile == false && (
              <div className="col-md-2 col-3 menu-item-small-screen">
                {" "}
                {router.pathname == "/checkout" ||
                  router.pathname == "/login" ? (
                  ""
                ) : (
                  <>
                    {router.asPath == "/product/weight_loss_mw" || router.asPath == "/product/weight_loss" ?
                      "" :
                      <MenuItems hideNav={hideNav} navDisplay={navDisplay} setNavDisplay={setNavDisplay} activeMenu={activeMenu} menuVal={menuVal} />
                    }
                  </>
                )}
              </div>
            )}
          </>
        )}
        {(group && router.query.group) ||
          router.query.quest != undefined ? (
          ""
        ) : (
          <>
            {router.asPath == "/product/weight_loss_mw" || router.asPath == "/product/weight_loss" ? "" :
              <div className="col-md-5 col-6">
                <div className="action-btn-navbar">
                  {router.pathname == "/weight_loss" ||
                    router.pathname == "/weight_loss_sw" ||
                    router.pathname == "/weight_loss_mw" ||
                    router.pathname == "/what-is-wegovy" ||
                    router.pathname == "//what-is-ozempic" ||
                    router.pathname == "/what-is-rybelsus" ||
                    router.pathname == "/what-is-saxenda" ||
                    router.pathname == "/what-is-generic-bupropion" ||
                    router.pathname == "/weight_loss_rw" ? (
                    <>

                      <div>
                        <a
                          href="#view-tests"

                        > <div className="login-navbar ">

                            <span
                              className="login"
                              style={{ color: "#363535", cursor: "pointer" }}
                            >
                              Get Started Now </span>
                          </div>
                        </a>
                      </div>&nbsp;
                      <Link href="/login">
                        <div className="login-navbar ">
                          <span
                            className="login"
                            style={{ color: "#363535", cursor: "pointer" }}
                          >
                            {window.sessionStorage.getItem("isLoggedIn")
                              ? "Account"
                              : "Login"}
                          </span>
                        </div>
                      </Link>
                    </>
                  ) : (
                    <>
                      <Link href="/login">
                        <div className="login-navbar ">

                          <span
                            className="login"
                            style={{ color: "#363535", cursor: "pointer" }}
                          >
                            {window.sessionStorage.getItem("isLoggedIn")
                              ? "Account"
                              : "Login"}
                          </span>
                        </div>
                      </Link>
                      &nbsp;&nbsp;
                      {router.pathname == "/contact_us" ? (
                        ""
                      ) : (
                        <Link href="/contact_us">
                          <div className="login-navbar " style={{ marginRight: "25px" }}>

                            <span
                              className="login"
                              style={{
                                color: "#363535",
                                cursor: "pointer",

                              }}
                            >
                              Contact Us
                            </span>
                          </div>
                        </Link>
                      )}

                      {/* {
                        isMobile == false &&
                        <div className="social-media-navbar">
                          <a
                            href="https://www.instagram.com/next_medical/"
                            aria-label="Instagram"
                            target="_blank"
                            rel="nofollow"
                          >
                            <i className="fa-brands fa-instagram" style={{ fontSize: "20px", color: "#368fff" }}></i>
                          </a>
                          <a
                            href="https://www.facebook.com/JoinNextMed/"
                            aria-label="Facebook"
                            target="_blank"
                            rel="nofollow"
                          >
                            <i className="fa-brands fa-facebook" style={{ fontSize: "20px", color: "#368fff" }}></i>
                          </a>
                          <a
                            href="https://www.youtube.com/@joinnextmed"
                            aria-label="Youtube"
                            target="_blank"
                            rel="nofollow"
                          >
                            <i className="fa-brands fa-youtube" style={{ fontSize: "20px", color: "#368fff" }}></i>
                          </a>
                        </div>
                      } */}
                    </>
                  )}
                  {router?.pathname !== '' && router?.query?.call?.toLowerCase() == "true" ? <Link href="tel:+18555501960">
                    <div className="Call_nav desk-view ">
                      <span
                        className="call_nav_btn"
                        style={{
                          color: "#ffff",
                          cursor: "pointer",
                        }}
                      >
                        Call Now
                      </span>
                    </div>
                  </Link> : ""}
                  {router?.pathname !== '' && router?.query?.call?.toLowerCase() == "true" ?
                    <Link href="tel:+18555501960">
                      <div className="phone_nav_callus mobile-view">
                        <i className="fa fa-phone mobile_nav" aria-hidden="true"></i>
                      </div>
                    </Link> : ""}
                  <button
                    onClick={(e) => toggleShow()}
                    className="navbar-toggler megamobile-menu"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#mobileMegaMenuMain"
                    aria-controls="mobileMegaMenuMain"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <i className="fa fa-bars" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            }
          </>
        )}
      </div>
    </div >
  )
}

export default NavbarButtons;